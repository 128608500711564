







import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  mounted() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);

    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
    });

    this.$store.dispatch('login');
  },
});
